import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useAuth } from '../../Context/AuthContext'
import { Link } from 'react-router-dom'
import './Quote.css'
import moment from 'moment'
import { ActTypes } from '../../Constants'
import discoBall from '../../Assets/Icons/disco-ball.png'

export default function Quotes() {

  const [requests, setRequests] = useState([])
  const [acts, setActs] = useState([])

  let { currentUser } = useAuth()

  useEffect(() => {
    getRequests()
    getActs()
  }, [])

  const getActs = () => {
    axios.get(`${process.env.REACT_APP_API}/acts/get-acts`)
    .then((res) => {
      setActs(res.data)
    })
    .catch((error) => {
      console.log(error)
    })
  }

  const getRequests = () => {
    axios.post(`${process.env.REACT_APP_API}/requests/get-requests-with-quotes-by-client-id`, {id: currentUser.uid})
    .then((res) => {
      setRequests(res.data)
    })
    .catch((error) => {
      console.log(error)
    })
  }

  const togglePause = (requestId, onPause) => {
    axios.post(`${process.env.REACT_APP_API}/requests/toggle-pause`, {requestId, onPause})
    .then((res) => {
        getRequests()
    })
    .catch((error) => {
      console.log(error)
    })
  }

  const markAsRead = (quoteId) => {
    axios.post(`${process.env.REACT_APP_API}/quotes/mark-quote-read`, {quoteId})
    .then((res) => {
      // Rather than retrieve everything again with getRequest (all requests plus nested quotes)
      // for this client, trust the backend updated (we are in the success part after all) and
      // update the item directly. More efficient this way
      setRequests((prevRequests) => {
        return prevRequests.map((request) => ({
          ...request,
          quotes: request.quotes.map((quote) =>
            quote._id === quoteId
              ? { ...quote, readByClient: true }
              : quote
          ),
        }))
      })
    })
    .catch((error) => {
      console.log(error)
    })
  }

  return (
    <div className='client-outlet' style={{paddingRight: 0}}>
      <div style={{display:'flex', justifyContent:'space-between'}}>
        <div>
          <h1 style={{margin:0}}>Quotes</h1>
          <p style={{margin:0}}>View and manage your active quotes here</p>
        </div>
        <div style={window.innerWidth < 750 ? {textAlign: 'right', marginRight: '1rem'} : {textAlign: 'right', marginRight: '2rem'}}>
          <p style={{margin:0, width: 200, color: '#a9a9a9', fontSize: 12, marginBottom: 10}}>Want to book another act for another part of your day?</p>
          <Link to={'/find'}>
            <button className='primary-btn'>Request Another Act</button>
          </Link>
        </div>
      </div>

      <div id='client-request-list'>

        {requests.map((request, i) => {
          let startTime = moment.utc(request.info.startTime, "HH:mm");
          let endTime = moment.utc(request.info.endTime, "HH:mm");
          let icon = ActTypes.find((act) => act.name == request.info.actType)

          return (
            <div key={i} className='client-request-container' style={request.onPause ? {backgroundColor: '#f1f1f1'} : {}}>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <h2 style={{fontWeight: 900, marginTop: 0}}>Request for {request.info.actType} {request.onPause? "(Paused)" : ""}</h2>
                <img style={{width: '20%', marginLeft: 10, objectFit: 'contain'}} src={icon.icon ? icon.icon : discoBall} alt='First Dance Music Wedding Icon'/>
              </div>

              <div>
                <p className='request-info-header'>DATE: <span style={{fontWeight:700, letterSpacing: 0}}>{moment(request.date).format('dddd Do MMMM YYYY')}</span></p>
                <p className='request-info-header'>START: <span style={{fontWeight:700, letterSpacing: 0}}>{startTime.format("HH:mm A")}</span></p>
                <p className='request-info-header'>END: <span style={{fontWeight:700, letterSpacing: 0}}>{endTime.format("HH:mm A")}</span></p>
                <p className='request-info-header'>VENUE: <span style={{fontWeight:700, letterSpacing: 0}}>{request.info.venue}</span></p>

                <p className='request-info-header' style={{color: '#A9A9A9', marginTop: 20, height: 50, width: '95%', overflow:'hidden', textOverflow: 'ellipsis'}}>NOTES: <span style={{letterSpacing: 0}}>{request.notes}</span></p>

                {request.quotes.length < 1 ?
                <p style={{fontWeight: 900, color: '#A9A9A9'}}>No quotes provided</p>
                :
                request.quotes.map((quote, i) => {

                  let actData = acts.find(act => act._id === quote.selectedActId)
                  return (
                    <div key={i}>

                      <Link to={`/clients/quote/${quote._id}`} key={i} style={{textDecoration: 'none', color: '#313131', margin: 0}}>
                          <div className='quote-dash-container' style={{width: '100%', backgroundColor: 'white'}}>
                              <div className='quote-highlight'/>
                              <p style={{fontWeight: 900}}>{actData?.actName}</p>
                              <p style={{fontWeight: 900, margin: '5px 0'}}>£{quote.customerGross}</p>

                              <p style={{color:'#ecbdba', textDecoration: 'underline', fontWeight: 700}}>View Quote</p>
                          </div>

                      </Link>

                      {!quote.readByClient && (
                        <div>
                          <p style={{color: '#a9a9a9', fontSize: 13, margin: 5}}>Already viewed the quote?</p>
                          <button
                            onClick={() => markAsRead(quote._id)}
                            className='inverted-btn'
                            style={{borderRadius: 25, borderWidth: 1}}
                          >
                            Mark as Read
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                )}
              </div>

              <div>
                <p style={{color: '#a9a9a9', fontSize: 13, margin: 5}}>{request.onPause ? "Ready to get more quotes?": "Seen enough for now?"}</p>
                <button
                  onClick={() => togglePause(request._id, request.onPause ? false : true)}
                  className='inverted-btn'
                  style={{borderRadius: 25, borderWidth: 1}}
                >
                  {request.onPause ? "Unpause Quotes" : "Pause Quotes"}
                </button>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
