import React, { useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import './Request.css'
import moment from 'moment'
import { toast } from 'react-toastify'
import { useAuth } from '../../Context/AuthContext'
import { LogToServer } from '../../Utilities'
import hearts from '../../Assets/hearts.png'
import NewQuote from './Modals/NewQuote'
import send from '../../Assets/Icons/send.png'
import EditQuote from './Modals/EditQuote'
import QuoteContainer from './QuoteContainer'

// Description:
//   Page allows an artist to provide a quote in response to a client's request
//   Can also be revisited to update a previously supplied quote
//   Once a quote has been accepted (paid) this page will reflect that, no further
//   updates are permitted

export default function Request() {
    const AppCommission = 0.125
    const MinCommission = 30
    const MaxCommission = 500
    let { currentUser } = useAuth()
    let { id } = useParams()
    const [selectedActId, setSelectedActId] = useState("")
    const [latestQuotesMessage, setLatestQuotesMessage] = useState("")
    const [artistName, setArtistName] = useState("")
    const [artistEmail, setArtistEmail] = useState("")
    const [request, setRequest] = useState("")
    const [quoteOptions, setQuoteOptions] = useState([])
    const [acts, setActs] = useState([])
    const [requestRejected, setRequestRejected] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)

    //New Quote Modal
    const [newQuoteOpen, setNewQuoteOpen] = useState(false)
    //Edit Quote Modal
    const [editQuoteOpen, setEditQuoteOpen] = useState(false)
    const [quoteIndex, setQuoteIndex] = useState("")

    useEffect(() => {
        getRequest()
        getArtistInfo()
    }, [])

    useEffect(() => {
        if ((currentUser.uid) && (request._id)) {
            getQuotes()
        }
    }, [currentUser.uid, request._id])

    useEffect(() => {
        // once we have the artist info, retrieve the act info
        // any change to payment status, retrieve this again
        if (artistEmail) {
            getActInfo()
        }
    }, [artistEmail])

    const getRequest = () => {
        axios.post(`${process.env.REACT_APP_API}/requests/get-request-by-id`, {id})
        .then((res) => {
            setRequest(res.data)

            // in the case of an artist specific request the act will already
            // be specified by the client and should be pulled into the quote
            // Note that request stores the actId as actName :(
            if (res.data.actName) {
                setSelectedActId(res.data.actName)
            }

            // determine archived state
            const isArchived = res.data.archived?.some(
                (archiveEntry) => archiveEntry.artistId === currentUser.uid
            );
            setRequestRejected(isArchived)
        })
        .catch((error) => console.log("Error getting request by id", error))
    }

    const getArtistInfo = () => {
        axios.post(`${process.env.REACT_APP_API}/users/get-artist-by-id`, {id: currentUser.uid})
        .then((res) => {
            let {firstName, lastName, email} = res.data
            setArtistName(`${firstName} ${lastName}`); setArtistEmail(email)
        })
        .catch((error) => console.log("Error getting artist by id", error))
    }

    // retrieve all acts for the artist
    const getActInfo = () => {
        axios.post(`${process.env.REACT_APP_API}/acts/get-acts-by-id`, {
            artistId: currentUser.uid,
            published: true
        })
        .then((res) => {
            setActs(res.data);
        })
        .catch((error) => console.log("Error getting acts by id", error))
    }

    const getQuotes = () => {
        // get all the quotes for the artist for that request
        axios.post(`${process.env.REACT_APP_API}/quotes/get-artist-quotes-for-request`, {
            artistId: currentUser.uid,
            requestId: request._id
        })
        .then((res) => {
            setQuoteOptions(res.data)
        })
        .catch((error) => {
            console.log("Error getting artist quote for request", error)
        })
    }

    const roundToNearest = (amountToRound, roundPoint) => {
        return Math.round(amountToRound / roundPoint) * roundPoint
    }

    function calculateCustomerGross(gross, AppCommission) {
        // Apply app commission to the gross artist cost for saving with the quote.
        // But first enforce the minimum and maximum commission and also round this
        // figure to a customer friendly value
        let commission = gross * AppCommission;
        if (commission < MinCommission) {
            commission = MinCommission
        } else if (commission > MaxCommission) {
            commission = MaxCommission
        }

        const total = gross + commission
        return roundToNearest(total, 5)
    }

    function artistGross(amount, vat) {
        return Math.round(amount * vat * 100) / 100
    }

    const sendQuoteOptions = () => {
        if (quoteOptions.length < 1) {
            toast.error("Please add a quote")   // Shouldn't be able to happen due to conditional rendering
            return
        } else {
            // Guard against message text that includes pricing or emails
            // i.e. anything hinting at showing confusing pricing or backdooring the platform
            // TBD consider generic handler - duplicated in new quote and edit modals
            let errorString
            const grossAmounts = quoteOptions.map(quote => artistGross(quote.artistFee, quote.vatRate))
            const hasGrossInMessage = grossAmounts.some(gross => latestQuotesMessage.includes(gross.toString()))

            if (hasGrossInMessage) {
                errorString = "Please do not duplicate quote prices in your message text."
            }
            else if (latestQuotesMessage.includes(artistEmail)) {
                errorString = "Use in-app messaging for contact at this time in preference to direct email in your message text."
            }

            if (errorString) {
                toast.error(errorString)

                LogToServer(`Attempt to include illegal items in quote message by artist ${artistEmail}`, errorString)
                return
            }

            // check for warnable content in the message
            if (
                latestQuotesMessage.match(/\b\d{6,}\b/) ||
                latestQuotesMessage.match(/[a-zA-Z]+@[a-zA-Z]+/)
            ) {
                errorString = "Use in-app messaging for contact at this time."
                const userConfirmed = window.confirm(
                    "Message text advisory:\n\n" +
                    "Communication is securely handled by your app's inbox and is best managed there.\n\n" +
                    "If you feel your message is good to go then select OK to continue, otherwise cancel and amend as needed."
                )

                LogToServer(`Possible backdoor content in quote message by artist ${artistEmail}`, "Suspect content in quote message")

                if (!userConfirmed) {
                    return
                }
            }

            if (window.confirm("Are you sure you want to send your quotes?")) {

                setIsProcessing(true)

                let formattedQuotes = quoteOptions.map(option => {
                    let customerGross
                    let platformFee = 0

                    if (Number(option.artistFee) === 0) {
                        // this is considered a free booking, there should be no commission
                        customerGross = 0
                    } else if (Number(option.artistFee) === 0.3) {
                        // ! Payment Test Case !
                        // can make a test booking for 30p which is the stripe min
                        customerGross = 0.3
                    } else {
                        const gross = artistGross(option.artistFee, option.vatRate)
                        customerGross = calculateCustomerGross(gross, AppCommission)
                        // could use toFixed but let's be sure we don't take even 1p of client money
                        platformFee = Math.floor((customerGross - gross) * 100) / 100
                    }

                    return {
                        _id: option._id,
                        quoteName: option.quoteName,
                        artistId: currentUser.uid,
                        requestId: request._id,
                        artistFee: option.artistFee,
                        vatRate: option.vatRate,
                        customerGross: customerGross,
                        platformFee: platformFee,
                        hasPaid: false,
                        selectedActId: option.selectedActId,
                        selectedActName: option.selectedActName,
                        description: option.description,
                        readByClient: false,
                        updatedAt: new Date()
                    }
                })

                let requestPayload = {
                    artistId: currentUser.uid,
                    artistName,
                    artistEmail,
                    msg: latestQuotesMessage,
                    clientId: request.clientId,
                    clientEmail: request.email,
                    clientName: request.name,
                    requestId: request._id
                }

                axios.post(`${process.env.REACT_APP_API}/quotes/send-initial-quote-options`, { requestPayload, formattedQuotes } )
                .then((res)=>{
                    toast.success(res.data)
                    setLatestQuotesMessage("")
                    getQuotes()
                })
                .catch((error) => {
                    console.log("Error during quote creation/amendment", error)
                    toast.error(error.response.data.error || error.message || 'An error occurred')
                })
                .finally(() => setIsProcessing(false))

            } else {
                return
            }
        }
    }

    const unRejectRequest = () => {
        // unarchive the request for this artist
        axios.post(`${process.env.REACT_APP_API}/requests/unarchive-request`, {
            artistId: currentUser.uid,
            requestId: request._id
        })
        .then((archiveResponse) => {
            toast.success(archiveResponse.data.message)
            setRequestRejected(false)

            if (request.artistId)
            {
                // if the unarchive succeeded and this is a direct request then inform the client
                axios.post(`${process.env.REACT_APP_API}/requests/inform-client-of-unrejected-request`, {
                    artistId: currentUser.uid,
                    selectedActId,
                    clientEmail: request.email,
                    requestId: request._id
                })
                .then((informClientResponse) => {
                    toast.success(informClientResponse.data.message)
                })
                .catch((informClientError) => {
                    toast.error(informClientError.data.error.message)
                })
            }
        })
        .catch((archiveError) => {
            toast.error(archiveError.data.error.message)
        })
    }

    const rejectRequest = () => {
        // archive the request for this artist with status rejected
        console.log("Reject request - archive")

        axios.post(`${process.env.REACT_APP_API}/requests/archive-request`, {
            artistId: currentUser.uid,
            requestId: request._id,
            archiveType: "Rejected"
        })
        .then((archiveResponse) => {
            toast.success(archiveResponse.data.message)
            console.log("and inform client")

            setRequestRejected(true)

            if (request.artistId)
            {
                // if the archive succeeded and this is a direct request then inform the client
                axios.post(`${process.env.REACT_APP_API}/requests/inform-client-of-rejected-request`, {
                    artistId: currentUser.uid,
                    artistEmail,
                    artistName,
                    selectedActId,
                    clientId: request.clientId,
                    clientEmail: request.email,
                    clientName: request.name,
                    requestId: request._id
                })
                .then((informClientResponse) => {
                    toast.success(informClientResponse.data.message)
                })
                .catch((informClientError) => {
                    toast.error(informClientError.data.error.message)
                })
            }
        })
        .catch((archiveError) => {
            toast.error(archiveError.data.error.message)
        })
    }

    const noQuoteMade = () => {
        return !request.quoted?.includes(currentUser.uid);
    }

// TBD
// Deleting last quote option - does that remove also from the request quoted array? That artist should no longer appear there
// But what if the client has seen it? Indeed what if they are about to pay for it. Or at least read it and were gonna come back to it
//    - THEN IF IT HAS BEEN accepted but this page just NOT REFRESHED then we need to check on back end
// maybe guard by readByClient - if read then show it still but remove details or state the music option is no longer available
// SCHEDULING ISSUE - if a artist creates a quote then removes it, the scheduler will still see the record and inform the
// client that there are unread quotes
// BUG For quote page the server side get quotes was changed to exclude deleted but it seems it still gets them for wedding page and quote list page
// BUG when I deleted the last quote but had not submitted my new quote it made all disappear, I assume because it recognised none were left but there was one just unsubmitted

    const handleDeleteQuote = (index, quoteId) => {
        if (window.confirm("Are you sure you want to delete quote?")) {
            if (!quoteId) {
                // Unsaved quote and can just be removed from array
                let newArr = quoteOptions.filter((_, i) => index !== i)
                setQuoteOptions(newArr)
            } else {
                axios.post(`${process.env.REACT_APP_API}/quotes/delete-quote-option`, { quoteId, requestId: request._id } )
                .then((res)=>{
                    getQuotes()
                })
                .catch((error) => {
                    let errMsg
                    if (error?.response?.data?.error) {
                        errMsg = error.response.data.error
                    } else {
                        errMsg = "Error deleting quote option"
                    }

                    console.log(errMsg, error)
                    toast.error(errMsg)
                })
            }
        } else {
            return
        }
    }

    // there's some complexity here:
    //   - if a quote has been made then display amend
    //   - if a quote has not been made then display create
    //   - if a quote has been paid then state that it is no longer changeable
    //   - if a request has been paid or if it was only ever directed to a single artist
    //     then the artist Id should be present in the request. If another artist manages
    //     to land here then safeguard by providing a message that the request is covered
    // additionally, concerning rejection:
    //   - only an unquoted request can be rejected
    //   - rejected requests can be unrejected

    return (
        <div className='business-outlet' style={{display:'flex', flexDirection: 'row', alignItems:'baseline', flexWrap:'wrap'}}>

            <div id='wedding-request-info'>
                <h2 style={{fontWeight: 900, marginBottom: 30}}>Wedding Details</h2>
                <p className='request-header'>Client name - <span className='request-line-info'>{request && request.name}</span></p>
                <p className='request-header'>Date of wedding - <span className='request-line-info'>{moment(request.date).format('ddd, DD MMM YYYY')}</span></p>
                <p className='request-header'>Act requested - <span className='request-line-info'>{request && request.info.actType}</span></p>
                <p className='request-header'>Start time - <span className='request-line-info'>{request && request.info.startTime}</span></p>
                <p className='request-header'>End time - <span className='request-line-info'>{request && request.info.endTime}</span></p>
                <p className='request-header'>Venue - <span className='request-line-info'>{request && request.info.venue}</span></p>
                <p className='request-header'>Location - <span className='request-line-info'>{request && request.info.location}</span></p>
                <p className='request-header'>Notes -</p>
                <p className='request-line-info'>{request.notes}</p>

                {noQuoteMade() ? (
                    requestRejected ? (
                        <div>
                            <button
                                onClick={unRejectRequest}
                                className='transparent-btn'
                            >
                                Unreject Request
                            </button>
                            <p style={{ marginTop: 15, fontSize: 14, color: 'grey' }}>You previously rejected this request.</p>
                        </div>
                    ) : (
                        <div>
                            <p style={{marginTop: 30, fontWeight: 900, color: '#EBA7A2'}}>Can't do this wedding?</p>
                            <button
                                onClick={rejectRequest}
                                className='transparent-btn'
                            >
                                Reject Request
                            </button>
                            <p style={{ marginTop: 15, fontSize: 14, color: 'grey' }}>
                             If this was a direct request the sender will be notified.
                            </p>
                        </div>
                    )
                ) : null}

                <img id='artist-request-hearts'  src={hearts} alt='First Dance Music Wedding hearts'/>
            </div>

            <div id='create-quotes-container'>
                {(request.artistId && currentUser.uid !== request.artistId) ? (
                    <div>
                        <h2>This request has been accepted by another artist</h2>
                    </div>
                ) : (requestRejected) ? (
                    <div>
                        <h2 style={{fontWeight: 900, marginBottom: '5px'}}>
                            You have rejected this request
                        </h2>
                        <p style={{ fontSize: '14px', marginTop: '0px', marginBottom: '20px', marginLeft: '5px' }}>
                            If you would like to provide a quote, please unreject
                        </p>
                    </div>
                ) : (
                    <div>
                        <>
                            {request.fulfilled ? (
                                <>
                                    <h2 style={{fontWeight: 900, marginBottom: '0px'}}>
                                        Your quote has been accepted and paid
                                    </h2>
                                    <p style={{ fontSize: '12px', marginTop: '0px', marginBottom: '20px' }}>
                                        (no further changes are now possible)
                                    </p>
                                </>
                            ) : (
                                <h2 style={{fontWeight: 900}}>Create Your Quotes</h2>
                            )}

                            {quoteOptions.length < 1 ?
                                <button className='transparent-btn' onClick={() => setNewQuoteOpen(true)}>Get Started</button>
                            :   <>
                                {quoteOptions.map((quote, i) => {
                                    return (
                                        <QuoteContainer
                                            key={i}
                                            quote={quote}
                                            i={i}
                                            handleDeleteQuote={handleDeleteQuote}
                                            setEditQuoteOpen={setEditQuoteOpen}
                                            setQuoteIndex={setQuoteIndex}
                                            fulfilled={request.fulfilled}
                                        />
                                    )
                                })}

                                <p style={{ fontSize: 12 }}>
                                        Our platform fee is passed on to the client ensuring the artist always receives what they deserve.
                                        To avoid confusion, it is important that the above quoted artist costs are <b>not</b> included in any
                                        message entered below. See{' '}
                                        <a href="/artist-terms" target="_blank" rel="noopener noreferrer" style={{color:'#ecbdba', fontWeight: 900}}>
                                            Artist Terms & Conditions
                                        </a>.
                                </p>

                                {!request.fulfilled && <>
                                    <div id='request-msg-container'>
                                        <p style={{fontSize: 13, fontWeight: 700}}>Your message:</p>
                                        <textarea
                                            id='request-msg-text-box'
                                            value={latestQuotesMessage}
                                            onChange={(e) => setLatestQuotesMessage(e.target.value)}
                                            placeholder={`e.g. Hello ${request.name} and congratulations! ${'\n\n'}Please find our music quote attached. Any questions let us know. ${'\n\n'}Best regards, ${'\n'}${artistName}`}
                                        />
                                    </div>


                                    <div style={{display: 'flex', justifyContent:'space-between', alignItems:'center', flexWrap:'wrap'}}>
                                        <button
                                            className='transparent-btn request-btn'
                                            onClick={() =>setNewQuoteOpen(true)}
                                        >
                                            Add quote option
                                        </button>
                                        <button
                                            className='white-btn request-btn'
                                            onClick={sendQuoteOptions}
                                            disabled={isProcessing}
                                        >
                                            {isProcessing ? "Sending" : `Send Quote${quoteOptions.length > 1 ? "s" : ""}`}
                                            <img style={{height: 20, marginLeft: 10}} src={send} alt='Send First Dance Music Wedding Quote'/>
                                        </button>
                                    </div>
                                </>}
                            </>
                            }
                        </>

                    </div>)
                }
            </div>

            {newQuoteOpen &&
                <NewQuote
                    setOpen={setNewQuoteOpen}
                    acts={acts}
                    setQuoteOptions={setQuoteOptions}
                    artistEmail={artistEmail}
                />
            }
            {editQuoteOpen &&
                <EditQuote
                    setOpen={setEditQuoteOpen}
                    acts={acts}
                    quoteOptions={quoteOptions}
                    setQuoteOptions={setQuoteOptions}
                    quoteIndex={quoteIndex}
                    artistEmail={artistEmail}
                />
            }
        </div>
    )
}
